@font-face {
  font-family: "Segoe-Bold";
  src: url("../public/fonts/Segoe/Segoe-Bold.ttf") format("truetype"),
    url("../public/fonts/Segoe/Segoe-Bold.woff") format("woff"),
    url("../public/fonts/Segoe/Segoe-Bold.woff2") format("woff2"),
    url("../public/fonts/Segoe/Segoe-Bold.eot") format("eot");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe-SemiBold";
  src: url("../public/fonts/Segoe/SegoeUI-Semibold.ttf") format("truetype"),
    url("../public/fonts/Segoe/SegoeUI-Semibold.eot") format("woff"),
    url("../public/fonts/Segoe/SegoeUI-Semibold.woff2") format("woff2"),
    url("../public/fonts/Segoe/SegoeUI-Semibold.eot") format("eot");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe-Semilight";
  src: url("../public/fonts/Segoe/SegoeUI-Semilight.ttf") format("truetype"),
    url("../public/fonts/Segoe/SegoeUI-Semilight.woff") format("woff"),
    url("../public/fonts/Segoe/SegoeUI-Semilight.woff2") format("woff2"),
    url("../public/fonts/Segoe/SegoeUI-Semilight.eot") format("eot");
  font-weight: bold;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Segoe-Semilight", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
