@import "react-alice-carousel/lib/alice-carousel.css";

* {
  margin: 0;
  padding: 0;
}

.app {
  overflow-x: hidden;
}

main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}

/* Source Color Code: https://colorhunt.co/palette/2c3333395b64a5c9cae7f6f2 */
:root {
  --color-green: rgb(3, 63, 58);
  --color-0: rgb(20, 23, 23);
  --color-1: rgb(44, 51, 51);
  --color-1-a: rgba(44, 51, 51, 0.8);
  --color-2: rgb(57, 91, 100);
  --color-2-a: rgba(57, 91, 100, 0.7);
  --color-3: rgb(165, 201, 201);
  --color-4: rgb(231, 246, 242);
  --color-5: rgb(195, 250, 250);
  --color-6: white;
  --fadeInTime: 1s;
  --padding-Thickness: 3%;
}

.primary {
  background-color: var(--color-6);
  --color-text: var(--color-1);
  --color-heading: var(--color-2);
  --color-contrast: var(--color-0);
  --color-button-background: var(--color-3);
  --color-button-border: var(--color-1);
  --color-button-text: var(--color-1);
}

/* complementary color scheme */
.secondary {
  --color-background: var(--color-1);
  --color-text: var(--color-6);
  --color-heading: var(--color-5);
  --color-contrast: var(--color-5);
  --color-button-background: var(--color-1);
  --color-button-border: var(--color-5);
  --color-button-text: var(--color-5);
}

/* graphics color scheme */
.graphics {
  --color-background: var(--color-3);
  --color-text: var(--color-0);
  --color-heading: var(--color-contrast);
}

/* headings */
h1 {
  font-size: clamp(2.8em, 8vw, 4.7em);
  font-family: "Segoe-Semibold", sans-serif;
  width: 50%;
}

h2 {
  font-size: clamp(2.5em, 5vw, 3.2em);
  font-family: "Segoe-Bold", sans-serif;
}

h3 {
  font-family: "Segoe-Bold", sans-serif;
  padding-bottom: var(--padding-Thickness);
}

h4 {
  font-family: "Segoe-Bold", sans-serif;
  font-size: 0.9em;
  padding-bottom: var(--padding-Thickness);
}

.footerHeading {
  font-size: 1em;
  padding-bottom: var(--padding-Thickness);
  color: var(--color-heading);
  /* font-family: "Segoe-Semibold"; */
}

p {
  font-size: clamp(1em, 1.8vw, 1.3em);
  line-height: 1.6;
}

/* button and link styling */
button,
.buttonLinks {
  font-family: "Segoe-Semibold", sans-serif;
  font-size: 1.1em;
  background-color: var(--color-button-background);
  color: var(--color-button-text);
  border: 2px solid var(--color-button-border);
  border-radius: 50px;
  min-width: 150px;
  padding: 10px;
  margin: auto;
  text-align: center;
  letter-spacing: 0.3px;
  display: inline;
  transition: 0.3s;
  z-index: 2;
  text-decoration: none;
}

button:hover,
.buttonLinks:hover,
.buttonLinks:focus-within {
  cursor: pointer;
  background-color: var(--color-button-text);
  color: var(--color-button-background);
  border: 2px solid var(--color-button-background);
  border-radius: 50px;
}

button:active,
.buttonLinks:active {
  transform: translate(3px, 3px);
}

.lng {
  background-color: var(--color-3);
  color: var(--color-1);
  border: 2px solid var(--color-1);
}

.checked {
  cursor: pointer;
  background-color: var(--color-1);
  color: var(--color-3);
  border: 2px solid var(--color-3);
  border-radius: 50px;
}

/* link styling without button */
a {
  font-family: "Segoe-Semibold", sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  display: inline-block;
  box-sizing: border-box;
  min-width: 30px;
  text-align: center;
  padding: 2%;
  border-radius: 50px;
  transition: 0.3s;
  text-decoration: none;
}

a:hover {
  background-color: var(--color-text);
  color: var(--color-background);
}

.inlineLink {
  text-decoration: underline;
  text-underline-offset: 4px;
  color: var(--color-text);
  height: auto;
  padding: 0px 20px;
}

.inlineLink:hover {
  color: var(--color-6);
  background-color: var(--color-contrast);
}

/* overall page styling */
section {
  width: 100%;
  /* background-color: var(--color-background);
  color: var(--color-text); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  max-width: 1400px;
}

.content {
  width: 80%;
  margin-top: var(--padding-Thickness);
  margin-bottom: var(--padding-Thickness);
}

.sectionTitle {
  padding: var(--padding-Thickness);
  color: var(--color-heading);
  width: 100%;
}

/* Skip Link */
.skip {
  position: absolute;
  left: -100000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* VS Logo */
.VSlogo-top {
  height: 0px;
  width: 100px;
  /* position: sticky;
  top: 0px;
  left: 0px; */
  /* z-index: 2; */
}

/* headerHome */
.headerHome {
  padding: 5px;
  height: 7vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(20, 23, 23, 0) 100%
  );
  animation-delay: 1000ms;
  animation: fadeIn ease var(--fadeInTme);
  -webkit-animation: fadeIn ease var(--fadeInTme);
  -moz-animation: fadeIn ease var(--fadeInTime);
  -o-animation: fadeIn ease var(--fadeInTime);
  -ms-animation: fadeIn ease var(--fadeInTime);
  overflow-y: hidden;
}

/* .menuContainer {
  z-index: 99;
} */

#menu {
  position: fixed;
  top: 5px;
  right: 60px;
  font-family: "Roboto", sans-serif;
  font-size: 1.6em;
  z-index: 99;
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: var(--color-6);
  -webkit-text-fill-color: var(--color-6);
}

/*landingpage*/
.startpage {
  background-color: var(--color-0);
  margin: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  /* ab hier startimage */
  min-height: 430px;
  background-image: url("../../public/images/Verena-Schwachmeyer-Coding-2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  animation: fadeIn ease var(--fadeInTme);
  -webkit-animation: fadeIn ease var(--fadeInTme);
  -moz-animation: fadeIn ease var(--fadeInTime);
  -o-animation: fadeIn ease var(--fadeInTime);
  -ms-animation: fadeIn ease var(--fadeInTime);
}

figcaption {
  color: var(--color-2);
  position: absolute;
  bottom: 10px;
  right: 0px;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  font-size: 0.6em;
  height: 150px;
}

.homeLogoTop {
  background: none;
  position: sticky;
  top: 25px;
  left: 25px;
  z-index: 2;
}

.startTextContainer {
  box-sizing: border-box;
  position: absolute;
  left: 5%;
  top: 32vh;
  max-width: 600px;
}

.startText {
  opacity: 0;
  animation: fadeIn ease var(--fadeInTime);
  -webkit-animation: fadeIn ease var(--fadeInTime);
  -moz-animation: fadeIn ease var(--fadeInTime);
  -o-animation: fadeIn ease var(--fadeInTime);
  -ms-animation: fadeIn ease var(--fadeInTime);
  animation-fill-mode: forwards;
}

.startTextContainer > h1 {
  text-align: left;
  color: var(--color-5);
}

.startTextContainer > p {
  text-align: left;
  color: var(--color-5);
}

.wrapper {
  color: var(--color-3);
  display: grid;
  place-items: right;
  width: 30%;
  width: 300px;
}

#softwareDeveloper {
  font-family: "Courier New", Courier, monospace;
  font-size: clamp(1em, 1.8vw, 1.2em);
  font-weight: bold;
  color: var(--color-5);
  opacity: 0;
  margin: 2%;
  animation: typing 2s steps(19, end), blink 0.5s step-end infinite alternate;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  white-space: nowrap;
  border-right: 3px solid;
  overflow: hidden;
  padding: 0;
}

.landingPageLogo {
  color: var(--color-5);
  padding: 10px;
  font-size: 30px;
}

#contactMebutton,
#moreInfobutton {
  background-color: transparent;
  border: 2px solid var(--color-5);
  color: var(--color-5);
  margin-top: 7vh;
  position: relative;
  left: 25%;
}

#contactMebutton:hover,
#moreInfobutton:hover {
  background-color: var(--color-5);
  color: var(--color-1);
}

.buttonContainer {
  display: flex;
  width: 70%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/* .lottieArrow {
  position: absolute;
  top: 50vh;
} */

/* Header */
.headerSmall {
  margin: 0;
  padding-left: 2%;
  height: 5vh;
  min-height: 100px;
  width: 100%;
  text-align: center;
  background-image: url("../../public/images/Verena-Schwachmeyer-Coding-2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLogo {
  margin: var(--padding-Thickness);
}

/* Services */

.serviceContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.serviceFlipCard {
  background-color: transparent;
  width: 40%;
  min-width: 300px;
  height: 40vh;
  max-height: 330px;
  min-height: 300px;
  perspective: 1000px;
  margin: 2%;
}

.serviceCard {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: box-shadow 0.9s, transform 0.9s;
  transform-style: preserve-3d;
  border-radius: 5px;
  box-shadow: 0px 2px 3px 0px var(--color-1);
  background-color: var(--color-background);
  color: var(--color-contrast);
}

.serviceFlipCard:hover .serviceCard {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 1px 6px 6px 0px var(--color-1);
}

/* .flip .serviceCard {
  transform: rotateY(180deg);
} */

.serviceFlipCard:focus .serviceCard {
  transform: rotateY(180deg);
}

.serviceCard-front,
.serviceCard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 5px;
}

.serviceCard-front {
  background-color: var(--color-3) !important;
}

.serviceCard-back {
  transform: rotateY(180deg);
}

.serviceCard-back h3 {
  font-size: 100%;
  color: var(--color-heading);
}

.serviceCard-back h4 {
  font-size: clamp(1.3em, 1.8vw, 1.7em);
  color: var(--color-heading);
  margin: 4%;
  text-align: center;
}

.serviceCard-back li {
  font-size: clamp(0.9em, 1.3vw, 1.1em);
}

.serviceContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background);
  color: var(--color-6);
}

.serviceLogo {
  color: var(--color-text);
  font-size: 700%;
  min-width: 150px;
  padding: 5%;
  align-self: center;
  justify-self: center;
}

.serviceTitle {
  color: var(--color-text);
  font-size: 1.7em;
  text-align: center;
  text-transform: capitalize;
  border-radius: 5px;
}

#services > div > div > h3 {
  font-size: clamp(1.7em, 5vw, 2em);
  color: var(--color-heading);
  text-align: center;
}

/* AboutMe */
.container4Logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20%;
  padding: var(--padding-Thickness);
  text-align: left;
  font-size: 0.9em;
}

.info .logos {
  color: var(--color-1);
}

#portrait {
  height: 30vh;
  min-height: 100px;
  border-radius: 50%;
}

.aboutmeText {
  /* font-size: clamp(0.9em, 1.1em, 1.3em); */
  line-height: 2;
  text-align: justify;
  text-align: center;
  max-width: 1000px;
  margin: auto;
  padding: var(--padding-Thickness);
}

.portrait-info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
  color: var(--color-text);
  border-radius: 5px;
  padding: 2%;
}

.portrait-info > div > ul {
  line-height: 200%;
  font-size: clamp(1em, 1.8vw, 1.3em);
  padding: 15px;
  max-width: 850px;
}

.portrait-info > div > ul > li {
  padding: 0;
  width: 80%;
  text-align: left;
}

.link2Repo {
  color: var(--color-3);
}

.link2Repo:hover {
  color: var(--color-3);
}

#traits {
  color: var(--color-2);
  font-size: 1.5em;
  text-transform: capitalize;
  text-align: center;
  padding: var(--padding-Thickness);
  max-width: 1000px;
}

/* TechStack */
#techStack {
  padding-bottom: 30px;
}

.logoList {
  display: flex;
  justify-content: space-evenly;
  /* width: 100%; */
  color: var(--color-0);
  flex-wrap: wrap;
  list-style: none;
  max-width: 1000px;
}

/* .logos {
  color: var(--color-text);
  background-color: linear-gradient(145deg, var(--color-6), var(--color-5)); 
  display: flex;
  justify-content: space-evenly;
  max-width: 900px;
  padding: var(--padding-Thickness);
} */

.logoListItems {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 12%;
  height: 80px;
  width: 80px;
  margin: 2%;
  background: linear-gradient(
    180deg,
    rgba(225, 250, 250, 1) 0%,
    rgba(165, 201, 201, 1) 5%,
    rgba(165, 201, 201, 1) 95%,
    rgba(134, 159, 159, 1) 100%
  );
  border-radius: 10px;
}

.logoTile {
  background-color: var(--color-background);
  height: 70px;
  width: 70px;
  padding: 0.5%;
  margin: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#my-tooltip {
  background-color: var(--color-background);
  color: var(--color-text);
  border-radius: 5px;
  font-size: clamp(0.7em, 1.1em, 1.3em);
  /* font-size: 1.1em; */
  min-width: 100px;
  width: auto;
  max-height: 50px;
  padding: 3px;
  text-align: center;
}

/* Projects */

.project {
  margin: 50px;
  display: flex;
  align-items: center;
  height: 70vh;
  /* width: 70vw; */
  max-height: 500px;
  min-height: 300px;
  /* position: absolute; */
  overflow: hidden;
  background: var(--color-background);
  color: var(--color-text);
  border-radius: 5px;
  /* box-shadow: 1px 2px 5px 0px var(--color-2); */
  box-sizing: content-box;
}

.projectText {
  width: 40%;
  padding: var(--padding-Thickness);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.project h3 {
  font-size: 3.1em;
  padding: 0% 3% 5% 0%;
  color: var(--color-5);
  text-align: left;
}

.project h4 {
  font-size: 2.2em;
  padding: 0% 3% 5% 0%;
  color: var(--color-5);
  text-align: left;
  /* margin-top: 18%; */
}

.project p {
  font-size: 1.1em;
  line-height: 1.6;
  width: 90%;
  overflow-wrap: break-word;
  margin: 10px;
}

.poligon {
  /* display: inline-block; */
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  box-sizing: border-box;
  /* overflow: hidden; */
  clip-path: polygon(
    0% 0%,
    0% 50%,
    40px 0%,
    100% 0%,
    100% 100%,
    40px 100%,
    0% 50%
  );
  /* -webkit-clip-path: polygon(
    45px 0%,
    0% 50%,
    40px 100%,
    40px 100%,
    5px 50%,
    45px 0%
  );
  clip-path: 
    /* 0% 0%,
    0% 50%,
    80px 0%,
    100% 0%,
    100% 100%,
    80px 100%,
    0% 50%  polygon(
    40px 0%,
    0% 50%,
    45px 100%,
    45px 100%,
    5px 50%,
    40px 0%
  ); */
}

.projectScreenshot {
  object-fit: cover;
  position: absolute;
  left: 2px; /* equal to border thickness */
  height: 100%;
  width: 100%;
  clip-path: polygon(
    0% 0%,
    0% 50%,
    40px 0%,
    100% 0%,
    100% 100%,
    40px 100%,
    0% 50%
  );
}

.alice-carousel__stage-item {
  padding-right: 5%;
  overflow-y: auto;
  overflow-y: hidden;
}

.alice-carousel__dots {
  margin: 0px 0px 10px !important;
}
.carouselPrevButton {
  font-size: 2.5em;
  height: 90px;
  position: absolute;
  left: 0;
  top: 40%;
  color: grey;
  opacity: 0.5;
}

.carouselPrevButton:hover {
  opacity: 1;
}

.carouselNextButton {
  font-size: 2.5em;
  height: 90px;
  position: absolute;
  right: 0;
  top: 40%;
  color: grey;
  opacity: 0.5;
}

.carouselNextButton:hover {
  opacity: 1;
}

#slide1 {
  font-size: 2em;
}

/* Samples */
.sampleContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.cardContainer {
  position: relative;
  display: block;
  width: 35%;
  height: 25em;
  min-width: 300px;
  min-height: 500px;
  margin: 2%;
  padding: 2%;

  background: var(--color-background);
  border-radius: 5px;
  /* box-shadow: 1px 2px 5px 0px var(--color-2); */
  box-sizing: border-box;
}

.cardContainerContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.cardContainerContent h3 {
  color: var(--color-heading);
  font-size: 1.8em;
  text-align: center;
}

.cardContainerContent p {
  color: var(--color-text);
  margin: 10% 3% 3% 3%;
  font-size: 1.2em;
}

.cardContainer:hover .cardContainerContent,
.cardContainer:focus-within .cardContainerContent {
  cursor: pointer;
  opacity: 0.1;
  transition: 0.3s;
}

.screenshotSample {
  object-fit: cover;
  height: 65%;
  min-height: 250px;
  width: 90%;
  /* object-position: 50% 100%; */
}

.sampleLinkContainer {
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  width: 90%;
}

.sampleLink {
  margin: 2%;
  transition: 0.3s;
  opacity: 0;
  align-self: center;
  width: 190px;
}

.sampleLink:hover,
.sampleLink:focus-within {
  opacity: 1;
  z-index: 2;
  transition: 0.3s;
}

.sampleLink:focus-within ~ .cardContainerContent {
  opacity: 0.1;
}

.cardContainer:hover .sampleLink {
  opacity: 1;
  /* transform: translateY(150px); */
  transition-delay: 0.1s;
}

/* .sampleButtonContainer:hover ~ .cardContainerContent {
  opacity: 0.5;
} */

/* Footer */
footer {
  padding: 1%;
  width: 100%;
  min-height: 25vh;
  background-color: var(--color-1);
  color: var(--color-4);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

footer a {
  text-underline-offset: 3px;
}

.footerLogos {
  background-color: var(--color-1);
  color: var(--color-6);
  font-size: 18px;
  margin: 0px 10px 0px 10px;
}

.footerLogos:hover {
  background-color: var(--color-6);
  color: var(--color-1);
  border-radius: 5px;
}

.colContainer {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.col1 {
  flex-shrink: 1;
}

.col1,
.col2,
.col3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 300px;
  max-width: 25vw;
}

.col2 > a {
  width: 80%;
  margin: 2px;
}

.col3 > p {
  font-size: 0.9em;
}

.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#copyright {
  font-size: 0.9em;
  padding: 1%;
}

input,
textarea {
  background-color: var(--color-4);
  color: var(--color-contrast);
  outline: var(--color-2) solid 2px;
  outline-offset: 0px;
  font-size: 1.1em;
  width: 80%;
  height: 48px;
  padding: 1%;
  margin: 1%;
  border: 2px solid var(--color-3);
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe-Semilight", "Roboto",
    sans-serif;
}

textarea {
  height: 30vh;
  /* outline: var(--color-2) solid 2px;
  outline-offset: 0px; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe-Semilight", "Roboto",
    sans-serif;
  font-size: 1.1em;
}

input:focus,
textarea:focus {
  border: 2px solid var(--color-2);
  /* transform: scale(1.05); */
}

.formButton {
  display: flex;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
}

label {
  width: 80%;
  margin-top: 20px;
  /* text-align: left; */
}

.loadingSpinner {
  position: relative;
  top: -400px;
}

.modal {
  /* position: fixed; */
  height: 30vh;
  width: 50vw;
  margin: auto;
  position: absolute;
  top: 50%;
  /* width: 100%; */
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0px var(--color-2);
  background-color: var(--color-6);
  color: var(--color-1);
  padding: 50px;
  display: grid;
  z-index: 5;
}

.react-responsive-modal-modal {
  border-radius: 5px;
  padding: 100px;
}

.react-responsive-modal-closeButton {
  width: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  justify-content: flex-end;
}

.modal h3 {
  font-size: 2em;
  color: var(--color-1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(44, 51, 51, 0.6);
}

.modalButton {
  background-color: var(--color-5);
  color: var(--color-1);
  margin: auto;
  padding: 2%;
}

.modalButton:hover {
  background-color: var(--color-2);
  color: var(--color-4);
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 25px;
  top: 20px;
  opacity: 1;
  animation: fadeIn ease var(--fadeInTIme);
  -webkit-animation: fadeIn ease var(--fadeInTIme);
  -moz-animation: fadeIn ease var(--fadeInTIme);
  -o-animation: fadeIn ease var(--fadeInTIme);
  -ms-animation: fadeIn ease var(--fadeInTIme);
  animation-delay: 1000ms;
  animation-fill-mode: forwards;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--color-2);
  border-radius: 10px;
  border: solid 2px var(--color-6);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--color-5);
  border: solid 2px var(--color-0);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 40px !important;
  width: 40px !important;
  padding: 5px;
}

/* Color/shape of close button cross */
.bm-cross {
  background-color: var(--color-6);
  height: 30px !important;
  right: 10px !important;
  top: 5px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.menuHeader {
  width: 100vw;
  height: 100vh;
}

.bm-menu-wrap {
  position: fixed;
  top: 0;
  height: 100vh !important;
}

/* General sidebar styles */
.bm-menu {
  background: var(--color-1);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow-y: auto !important;
  height: 100vh !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: var(--color-3);
  padding: 0.8em;
}

/* Individual item */
#menu-item,
.menu-item {
  display: inline-block;
  margin-top: 10%;
  font-size: 1.4em;
  color: var(--color-6);
  background-color: var(--color-1);
  font-family: "Segoe-Semilight";
}

/* .bm-item:hover,
#menu-item:hover {
  color: var(--color-1);
  background-color: var(--color-4);
} */
/* background-color: var(--color-4);
  text-decoration: underline;
  text-underline-offset: 4px;*/

/* Styling of overlay */
.bm-overlay {
  color: var(--color-2);
  background: var(--color-2-a) !important;
  top: 0;
  width: 100vw !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes typing {
  0% {
    width: 0;
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    width: 19ch;
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@media only screen and (min-width: 1200px) {
  .logoList {
    margin: 0%;
  }
}

@media only screen and (max-width: 1000px) {
  .logoList {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    max-height: 250px;
  }

  h3 {
    padding: 20px;
  }

  .logoListItems {
    width: 90px;
  }

  .col1,
  .col2,
  .col3 {
    margin-top: 30px;
  }

  .carouselPrevButton {
    top: 80%;
    left: 10%;
    margin: 30px;
  }

  .carouselNextButton {
    top: 80%;
    right: 10%;
    margin: 30px;
  }

  .project h3 {
    font-size: 2.2em;
  }
}

/* cell phone width */
@media only screen and (max-width: 600px) {
  h2 {
    margin-top: 30px;
  }

  footer a {
    font-size: 1.5em;
    padding: 2%;
  }

  #menu {
    visibility: hidden;
  }

  .startpageContainer {
    width: 100%;
    height: 100%;
    min-height: 430px;
    /* position: absolute;
    top: 0; */
    background-color: rgba(0, 0, 0, 0.3);
  }

  .startpage {
    background-size: cover;
    background-position: right -90px top 0px;
  }

  .buttonContainer {
    width: 60%;
  }

  #contactMebutton,
  #moreInfobutton {
    margin-top: 3vh;
  }

  .portrait-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #portrait {
    width: 200px;
    height: auto;
  }

  .portrait-info > div > ul {
    margin: 0;
  }

  .header {
    max-height: 200px;
  }

  .content {
    width: 95%;
  }

  input,
  textarea {
    width: 100%;
  }

  #copyright {
    font-size: 0.7em;
  }

  .sample {
    min-width: 340px;
  }

  .project {
    margin: 0;
    width: 100%;
    flex-direction: column;
  }

  .project h4,
  .project h3 {
    margin-top: 20px;
  }

  .projectText {
    width: 90%;
  }

  .project p {
    font-size: 1em;
    width: 100%;
  }

  .project h3 {
    font-size: 2.2em;
  }

  .poligon {
    clip-path: none;
  }

  .projectScreenshot,
  .poligon {
    left: 0px;
    width: 100%;
    clip-path: none;
    object-position: center right;
    /* object-fit: contain; */
  }

  .alice-carousel {
    margin: 20px 0;
  }

  .alice-carousel__dots {
    margin: 15px 0px 10px !important;
  }
  /* 
  .carouselPrevButton {
    top: 80%;
  }

  .carouselNextButton {
    top: 82%;
  } */

  label {
    width: 100%;
  }

  .formButton {
    margin: 20px;
  }

  .projectScreenshot {
    border-top: 2px solid var(--color-6);
  }
}

#impressum h2 {
  color: var(--color-2);
}

#impressum h3,
#impressum h4,
#impressum h6 {
  color: var(--color-1);
  padding: 2% 0% 2% 0%;
}

#impressum p {
  font-size: 1em;
  text-align: justify;
}

#impressumContainer > p {
  color: var(--color-1);
  font-size: 0.8em;
}

#impressumQuelle {
  text-align: right;
}

/* Errorpage */
#errorpage {
  height: 58vh;
  background-color: var(--color-background);
  color: var(--color-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#errorH1 {
  font-family: "Segoe-Bold";
  text-align: center;
}

@media only screen and (max-height: 600px) {
  h1 {
    font-size: 2.8em;
  }
  .project h3 {
    font-size: 2.2em;
  }
}

/* Cell phone held wide */
@media only screen and (max-width: 1000px) and (orientation: landscape) {
  h1 {
    font-size: 2.8em;
    position: relative;
    top: -10px;
  }

  #contactMeButton {
    /* margin-top: 3px; */
    visibility: hidden;
  }

  .project {
    margin: 0;
  }

  .project h3 {
    font-size: 2.2em;
    margin-top: 0;
  }

  .project h4 {
    font-size: 1.8em;
    margin-top: 0;
  }

  .project p {
    font-size: 1em;
  }

  .carouselNextButton,
  .carouselPrevButton {
    margin: 0;
    top: 85%;
  }
}

@media only screen and (max-height: 400px) and (orientation: landscape) {
  h1 {
    font-size: 2.8em;
  }

  .project h3 {
    font-size: 2.2em;
    margin-top: 0;
  }

  .project h4 {
    font-size: 1.8em;
    margin-top: 0;
  }

  .project p {
    font-size: 1em;
  }

  #contactMebutton,
  #moreInfobutton {
    visibility: hidden;
  }
}

@media (prefers-reduced-motion) {
  #softwareDeveloper {
    opacity: 1;
    animation: none;
    border: none;
  }
  .serviceFlipCard:hover .serviceCard {
    transform: none;
  }
  .serviceFlipCard:focus .serviceCard {
    transform: none;
  }

  .serviceFlipCard:focus > div > .serviceCard-front {
    opacity: 0;
  }

  .serviceFlipCard:focus > div > .serviceCard-back {
    transform: none;
  }
}
